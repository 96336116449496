body {
    background-color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
    -webkit-tap-highlight-color: transparent;
    overflow: hidden;
}

::-webkit-scrollbar {
    width: 0px;
  }
  

.homepageCont {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    
}

.turnospageCont {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.homeTittle {
    color: black;
    font-size: 3rem;
    font-family: 'montserrat', cursive;
    margin: 2rem auto;
   
}

/* STACK */

.stackItemsCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100vw;
}

.stackImgCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 10%;
    border-radius:  10px;
    margin: 0.2rem;
    background-color: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(20px);
  
    aspect-ratio: 1/1;
    transition: 0.5s all ease-in-out;
}

.stackImgCont:hover {
    background-color:  rgba(219, 219, 219, 0.25);
}

.stackImg{
    width: 100%;
    border-radius:  10px;
    aspect-ratio: 1/1;
    object-fit: cover;
}

/* 

font-family: 'Abel', sans-serif;
font-family: 'Anton', sans-serif;
font-family: 'Barlow Condensed', sans-serif;
font-family: 'Cairo', sans-serif;
font-family: 'Cutive Mono', monospace;
font-family: 'Dosis', sans-serif;
font-family: 'Montserrat', sans-serif;
font-family: 'Nixie One', cursive;
font-family: 'Open Sans', sans-serif;
font-family: 'Poiret One', cursive;
font-family: 'Raleway', sans-serif;
font-family: 'Raleway Dots', cursive;
font-family: 'Roboto', sans-serif;
font-family: 'Roboto Slab', serif;
font-family: 'Rubik Mono One', sans-serif;
font-family: 'Teko', sans-serif;

*/















.ball1 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -500;
    width: 40%;
    aspect-ratio: 1/1;
    background: rgb(255,250,199);
background: linear-gradient(0deg, rgba(255,250,199,1) 0%, rgba(237,255,224,1) 59%);
border-radius: 50%;
filter: blur(50px);
opacity: 100%;
animation: movement 10s infinite;
}


.ball2 {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -500;
    width: 40%;
    aspect-ratio: 1/1;
    background: rgb(199,255,204);
    background: linear-gradient(0deg, rgba(199,255,204,1) 0%, rgba(255,224,224,1) 59%);
border-radius: 50%;
filter: blur(50px);
opacity: 60%;
animation: movement2 10s infinite;
}

.ball3 {
    position: absolute;
    z-index: -500;
    width: 40%;
    aspect-ratio: 1/1;
    background: rgb(255,250,199);
background: linear-gradient(0deg, rgba(255,250,199,1) 0%, rgba(237,255,224,1) 59%);
border-radius: 50%;
filter: blur(50px);
opacity: 60%;
animation: movement3 10s infinite;
}

.ball4 {
    position: absolute;
    z-index: -500;
    right: 5%;
    bottom: 0;
    width: 40%;
    aspect-ratio: 1/1;
    background: rgb(199,220,255);
    background: linear-gradient(0deg, rgba(199,220,255,1) 0%, rgba(224,255,239,1) 59%);
border-radius: 50%;
filter: blur(50px);
opacity: 60%;
animation: movement4 20s infinite;
}

.turnosCardTextCont {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    overflow: auto;
    height: 100vh;
    width: 90%;
    margin: 0rem;

}
.column {
    flex-direction: column;
    width: 100%;
}

.column2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}


.turnosTittle {
    font-family: 'Roboto Slab', serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 4rem;
    color: grey;
    width: fit-content;
    padding: 2rem 2rem;
    border-radius: 10px;
    margin: 1rem auto 0 ;
    animation: changeColor 10s infinite;
}

.turnosTittle2 {
    font-family: 'Roboto Slab', serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    color: grey;
    width: fit-content;
    padding: 2rem 2rem;
    border-radius: 10px;
    margin: 1rem auto 0 ;

}

.turnosTittleMini {
    font-family: 'Cairo', sans-serif;
    font-weight: 300;
    font-size: 0.9rem;
    margin: 0;
    color: grey;
    text-align: center;
    font-style: italic;
}

.turnosItemCont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Cairo', sans-serif;
    text-align: center;
    position: relative;
    background-color: rgb(255, 255, 255, 50%);
    border-radius: 20px;
    width: 20%;
    aspect-ratio: 1/1;
    padding: 0rem 2rem;
    margin: 1rem;
    transition: 0.5s all ease-in-out;
    box-shadow: 0px 0px 20px rgb(0, 0, 0, 5%);
    
}

.turnosItemImg {
    width: 4rem;
    position: absolute;
    top: -1rem;
    left: -1rem;
 
}

.turnosItemTittle {

    font-family: 'Roboto Slab', serif;
    font-weight: 500;
    font-size: 2rem;
    margin: 0 0 0.5rem 0 ;
    color: #313131;
}

.turnosItemDescription {
    font-family: 'Nixie One', cursive;
    font-weight: 100;
    font-size: 1rem;
    margin: 0.5rem 0 0 0;
    color: #313131;
    text-align: center;
}

.ItemDescription {
    font-family: 'Cairo', sans-serif;
    padding: 1rem;
    font-size: 0.8rem;
    margin: 0.5rem 0 0 0;
    color: #313131;
    text-align: center;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    background-color: rgb(255, 255, 255, 50%);
    border: 10rem;
}

.sitiosweb {
    width: 5rem;
    aspect-ratio: 1;
    border-radius: 50%;
    transition: 0.5s all ease-in-out;
    cursor: pointer;
    margin: 1rem;
    object-fit: cover;
}

.sitiosweb:hover {
    transform: scale(1.05);
}

.disappear {
    opacity: 10%;
    transition: 1s all ease-in-out;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.disappear:hover {
    opacity: 100%;
}

.rowOfItems {
    display: flex;
    flex-direction: row;
    width: 90%;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.wavesCont {
    overflow: hidden;
    height: 100vh;
    width: 100%;
    opacity: 50%;
}

.wave1 {
    position: absolute;
    bottom: 5%;
    width: 200%;
    animation:  move-forever2 25s cubic-bezier(.55,.5,.45,.5)     infinite;
    opacity: 90%;
}



  @keyframes move-forever {
    0% {
      transform: translate3d(-40%, 10%, 0);

    }
    30% { 
        transform: translate3d(0%, -5%, 0);        
      }

      
    100% { 
    
        transform: translate3d(-40%, 10%, 0);

    }
  }



@keyframes changeColor {
    0% {
       background-color: #c7dcff;
       border-radius: 73% 27% 86% 14% / 7% 87% 13% 93% ;
    }
    30% {
        background-color: #fffac7;
        border-radius: 20% 80% 85% 15% / 57% 19% 81% 43% 
    }
    60% {
        background-color: rgb(199,255,204);
        border-radius: 65% 35% 45% 55% / 81% 65% 35% 19% 
    }
    90% {
        background-color: rgb(255,250,199);
        border-radius: 87% 13% 74% 26% / 43% 33% 67% 57% 
    }
    100% {
        background-color: rgb(199,220,255);
        border-radius: 73% 27% 86% 14% / 7% 87% 13% 93% ;
     }
  }

@keyframes movement {
    0% {
        top: 0;
        left: 0;
    }
    25% {
        top: 50%;
        left: 70%;
    }
    50% {
        top: 2%;
        left: 14%;
    }
    75% {
        top: 77%;
        left: 49%;
    }
    100% {
        top: 0;
        left: 0;
    }
  }

  @keyframes movement2 {
    0% {
        bottom: 10%;
        left: 0;
    }
    25% {
        bottom: 77%;
        left: 22%;
    }
    50% {
        bottom: 20%;
        left: 60%;
    }
    75% {
        bottom: 17%;
        left: 90%;
    }
    100% {
        bottom: 10%;
        left: 0;
    }
  }

  @keyframes movement3 {
    0% {
        top: 10%;
        right: 0%;
    }
    25% {
        top: 14%;
        right: 75%;
    }
    50% {
        top: 0;
        right: 40%;
    }
    75% {
        top: 64%;
        right: 44%;
    }
    100% {
        top: 10%;
        right: 0%;
    }
  }

  @keyframes movement4 {
    0% {
        right: 5%;
        bottom: 0;
    }
    25% {
        right: 0%;
        bottom: 16%;
    }
    50% {
        right: 25%;
        bottom: 50%;
    }
    75% {
        right: 77%;
        bottom: 21%;
    }
    100% {
        right: 5%;
        bottom: 0;
    }
  }



  @media only screen and (min-width: 100px) and  (max-width: 600px) {


    .turnosItemCont {

        width: 70%;

        
    }

    .ball1 {
        display: none;
    }
    
    
    .ball2 {
        display: none;
    }
    
    .ball3 {
        display: none;
    }
    
    .ball4 {
display: none;
    }
    

  }