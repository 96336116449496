.trabajosCardListCont {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    overflow: auto;
    width: 100%;
    margin: 5rem;
}

.ShopCardCont {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25vw;
    height: 80vh;
    margin: 0.5rem;
    border-radius: 10px;    
    cursor: url("https://img.icons8.com/small/16/FFFFFF/360-degrees.png"), auto;
    transition: 1s all ease-in-out;
    text-decoration: none;
}

.ShopCardImg {
    width: 100%;
    aspect-ratio: 4/3;
    border-radius: 20px 20px 20px 0px ;
    object-fit: cover;
    transition: 0.5s all ease-in-out;
    filter: grayscale(0.2) ;
}


.ShopCardTextCont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Cairo', sans-serif;
    background-color: white;
    border-radius: 20px 0 20px 20px;
    width: 75%;
    padding: 1.5rem 2rem;
    position: relative;
    bottom: 5rem;
    transition: 0.5s all ease-in-out;
    box-shadow: 0px 0px 40px rgb(0, 0, 0, 10%);
}

.ShopCardTextCont:hover {
    transform: translateY(-5px);
}

.ShopCardTittle{
    font-family: 'Roboto Slab', serif;
    font-weight: 500;
    font-size: 2rem;
    margin: 0 0 0.5rem 0 ;
    color: #313131;
}


.ShopCardText {
    font-family: 'Nixie One', cursive;
    font-weight: 100;
    font-size: 0.8rem;
    margin: 0.5rem 0 0 0;
    color: #313131;
    text-align: center;
}

.rowIcons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0rem 0.5rem;
    height: 2rem;
}

.columnIcons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem 0.5rem;

}

.catCardImg {
    width: 1rem;
    aspect-ratio: 1/1;
    margin: 0 0.5rem;
}

.catCardText {
    font-family: 'Nixie One', cursive;
    font-weight: 100;
    font-size: 0.8rem;
    color: #313131;
}


@media only screen and (min-width: 100px) and  (max-width: 600px) {

    .ShopCardCont {
        width: 80vw;
        height: 45vh;
        margin: 1rem 0;
    }

  }