.NavBarCont {
    position: absolute;
    top: 0;
    height: 10vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.navBarItem {
    font-size: 1rem;
    font-family: 'Nixie One', cursive;
    text-decoration: none;
    transition: .5s all ease-in-out;
    padding: 1rem;
    color: black;
}

.navBarItem:hover {
    background-color: rgb(248, 235, 255);
    border-radius: 5px;
}




/* FOOTER */
.footer {
    position: absolute;
    bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; 
}

.footerImg {
    width: 1.5rem;
    aspect-ratio: 1/1;
    margin: 1rem;
    transition: 0.5s all ease-in-out;
    opacity: 60%;
    cursor: pointer;
}

.footerImg:hover {
    opacity: 100%;
}



@media only screen and (min-width: 100px) and  (max-width: 600px) {

    .navBarItem {
        font-size: 0.8rem;
    }

  }