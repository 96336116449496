

.profilePic {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 5rem;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border-top: solid rgb(235, 145, 145) 3px;;
    border-right: solid rgb(253, 220, 220) 3px;
    border-bottom: solid rgb(160, 148, 210) 3px;
    border-left: solid rgb(139, 215, 157) 3px;
    transition: 0.5s all ease-in-out;
    cursor: pointer;
    z-index: 500;
    opacity: 85%;

}


.profilePic:hover {
    transform:scale(1.05) rotate(-1deg) ;
    opacity: 100%;
    box-shadow: -5px 5px 20px rgb(197, 197, 197);
}

.bioBar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 5rem;
    background: rgb(240,240,240);
background: linear-gradient(0deg, rgba(240,240,240,1) 0%, rgba(255,255,255,1) 59%);
    transition:1s all ease-in-out;
    cursor: pointer;
    border-radius: 20px;
    opacity: 0%;
    transform: rotate(10deg);
}

.bioBarOpen {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2rem;
    position: absolute;
    top: 3rem;
    right: 3rem;
    width: 15rem;
    height: fit-content;
    background: rgb(240,240,240);
background: linear-gradient(0deg, rgba(240,240,240,1) 0%, rgba(255,255,255,1) 59%);
    border-radius: 10px;
    transition:1s all ease-in-out;
    cursor: pointer;
    box-shadow: -5px 5px 100px rgb(197, 197, 197);
    opacity: 80%;
    transform: rotate(0deg);
}

.bioBarText{
    font-size: 0.9rem;
    font-family: 'Nixie One', cursive;
    text-decoration: none;
    transition: .5s all ease-in-out;
    margin: 0;
    padding: 0.2rem 0;
 
}

.rowStack {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}

.margintop0 {
    margin-top: 0rem;
}

.stackImgSmall {
    width: 1.5rem;
    margin: 0.15rem;
    aspect-ratio: 1/1;
}


.loaderCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: white;
    position: absolute;
    top: 0;
    z-index: 2000;
    animation:  fadeOut 4s linear;
    animation-iteration-count: 1;
}

.loaderTextCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.whitebox {
    width: 100%;
    height: 10rem;
    background: rgb(255,255,255);
background: -moz-linear-gradient(0deg, rgba(255,255,255,1) 8%, rgba(255,255,255,0) 85%);
background: -webkit-linear-gradient(0deg, rgba(255,255,255,1) 8%, rgba(255,255,255,0) 85%);
background: linear-gradient(0deg, rgba(255,255,255,1) 8%, rgba(255,255,255,0) 85%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
position: absolute;
bottom: 0rem;
z-index: 500;
animation:  moveBox 2s infinite;

}



  @keyframes moveBox {
    0% {
        bottom: -2rem;

    }
    50% { 
        bottom: 2rem;        
      }

      
    100% { 
    
        bottom: -2rem;
    }
  }

  

  @keyframes fadeOut {
    0% {
        opacity: 100%;

    }

    70% {
        opacity: 50%;

    }

    100% { 
        opacity: 0%;
        display: none;
    }
  }



@media only screen and (min-width: 100px) and  (max-width: 600px) {

    .profilePic {
        top: 5rem;
    }
    
    .bioBar {
        top: 5rem;
    }
    .bioBarOpen {
        top: 5rem;
    }
  }